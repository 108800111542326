import { IThunkActionCreator } from '../../types/thunk';
import { getSeparateUrl, memoize } from '@cian/newbuilding-utils';
import { IOffersDataState } from '../../types/offersData';
import { triggerHeaderRegionChanged } from '../../utils/events/triggerHeaderRegionChanged';

const getSeparateUrlMemo = memoize(getSeparateUrl);

export function updateBrowserUrl(offersData: IOffersDataState): IThunkActionCreator {
  return (dispatch, getState) => {
    const {
      jsonQuery,
      location,
      region: { currentRegion },
      filters: { tags },
    } = getState();
    const { seo, fullUrl } = offersData;
    if (typeof window !== 'undefined' && fullUrl) {
      const { protocol: newProtocol, domain: newDomain, path: newPath } = getSeparateUrlMemo(fullUrl, location.path);
      const { protocol, domain, path } = getSeparateUrlMemo(window.location.href);

      const isGeoNeeded = isGeoPathAvaliable(location.subdomain, domain.split('.')[0]);
      const isGeoApplied = isGeoPathApplied(newPath, location.geoPath);
      const addGeoPath = isGeoNeeded && !isGeoApplied;
      const newPathPrefix = addGeoPath ? `/${location.geoPath}` : '';
      const preparedPath = `${newPathPrefix}${newPath}`;

      const isLocalDev = domain.indexOf('localhost') !== -1;

      if (newProtocol !== protocol || (!isLocalDev && newDomain !== domain)) {
        let url = fullUrl;

        if (isLocalDev) {
          url = url.replace(newDomain, window.location.host).replace(newProtocol, protocol);
        }

        window.location.href = url;
      } else if (preparedPath !== path) {
        const title = (seo && seo.title) || document.title || '';
        document.title = title;
        triggerHeaderRegionChanged(currentRegion);
        window.history.pushState(
          {
            jsonQuery,
            currentRegion,
            path: preparedPath,
            page: location.page,
            title,
            tags,
          },
          title,
          preparedPath,
        );
      }
    }
  };
}

function isGeoPathAvaliable(xSubdomain: string, windowSubdomain: string) {
  if (windowSubdomain !== 'www') {
    return false;
  }

  return windowSubdomain !== xSubdomain;
}

function isGeoPathApplied(path: string, geoPath: string) {
  return path.startsWith(`/${geoPath}`);
}
